<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="parent_form edit_task_form">
        <div class="row">
            <div class="item long">
                <el-form-item label="任务名称" prop="task_title">
                    <el-input v-model="ruleForm.task_title" placeholder="请输入任务名称"></el-input>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务类别" prop="task_type">
                    <el-select size="medium" v-model="ruleForm.task_type" popper-class="global_select_popper">
                        <el-option v-for="item in taskType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务年度" prop="task_year">
                    <el-date-picker size="medium" v-model="ruleForm.task_year" :clearable="false" type="year" value-format="yyyy" placeholder="任务年度" style="width: 100%;"></el-date-picker>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务起止时间" prop="time">
                    <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="-" start-placeholder="选择日期" end-placeholder="选择日期"
                                    :clearable="false" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务属性" prop="task_quality">
                    <el-select size="medium" v-model="ruleForm.task_quality" popper-class="global_select_popper">
                        <el-option v-for="item in taskQuality" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="item long file_box">
                <el-form-item label="任务描述" prop="task_des">
                    <el-input type="textarea" :rows="4" v-model="ruleForm.task_des" placeholder="请输入任务描述"
                              :style="{marginBottom:ruleForm.file_ids&&ruleForm.file_ids.length>0?'10px':0}"></el-input>
                    <fileListComp :file_list="ruleForm.file_ids" @change="editNameBack" />
                    <div><el-button type="text" @click="updateFile">上传附件</el-button></div>
                </el-form-item>
            </div>
            <div class="item users">
                <el-form-item label="任务负责人" prop="fuze_uids">
                    <span class="names" v-if="ruleForm.fuze_uids&&ruleForm.fuze_uids.length>0">{{ruleForm.fuze_uids.map(item=>item.dingding.nickname).join('、')}}
                        <i class="el-icon-circle-close" @click="ruleForm.fuze_uids=[]"></i>
                    </span>
                    <el-button type="text" @click="choosePeople('fuze_uids')">选择任务负责人</el-button>
                </el-form-item>
            </div>
            <div class="item users">
                <el-form-item label="任务承办人" prop="deal_uids">
                    <span class="names" v-if="ruleForm.deal_uids&&ruleForm.deal_uids.length>0">{{ruleForm.deal_uids.map(item=>item.dingding.nickname).join('、')}}
                        <i class="el-icon-circle-close" @click="ruleForm.deal_uids=[]"></i>
                    </span>
                    <el-button type="text" @click="choosePeople('deal_uids')">选择任务承办人</el-button>
                </el-form-item>
            </div>
            <div class="item round" v-if="!ruleForm.task_rank">
                <el-form-item label="任务上报频率" prop="round">
                    <span v-if="ruleForm.round" style="margin-right: 10px;">{{initShowRound(ruleForm.round)}}</span>
                    <chooseRound :parent_task="ruleForm" @roundChange="roundChange"></chooseRound>
                </el-form-item>
            </div>
            <div class="item long child" v-if="ruleForm.task_rank<2">
                <span>子任务</span>
                <el-button type="text" @click="addorEditChild('add')">添加子任务</el-button>
            </div>
            <div class="item long table" v-if="childList.length>0">
                <el-table :data="childList">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="task_title" label="标题" align="center"></el-table-column>
                    <el-table-column label="任务类别" align="center">
                        <template slot-scope="scope">
                            <span>{{initTaskType(scope.row.task_type)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务起止时间" align="center"  width="210px">
                        <template slot-scope="scope">
                                <span v-if="scope.row.start_time&&scope.row.end_time">
                                    {{scope.row.start_time.slice(0,10).replace(/-/g,'.')}}
                                    -
                                    {{scope.row.end_time.slice(0,10).replace(/-/g,'.')}}
                                </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务负责人" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.fuze_uids">{{scope.row.fuze_uids.map(item=>item.dingding.nickname).join('、')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务承办人" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.deal_uids">{{scope.row.deal_uids.map(item=>item.dingding.nickname).join('、')}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="addorEditChild('edit',scope.row)">编辑</el-button>
                            <delTask :enter="3" :task_info="scope.row" @refresh="childTaskList" style="margin-left: 10px;"></delTask>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="button">
            <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
        </div>
    </el-form>
</template>

<script>
    import api from '@/api/methods/index';
    import peoplePicker from '@/utils/people_picker';
    import myPublic from '@/utils/public.js';
    import {mapState,mapMutations} from 'vuex';
    import computedData from '@/mixins/computedData.js';
    import chooseRound from '@c/task/round/choose_round.vue';
    import dingDisk from '@/utils/ding_disk';
    import delTask from '@c/task/delete/delete_task.vue';
    import fileListComp from '@/components/file/index.vue'

    export default {
        mixins:[computedData],
        components:{chooseRound,delTask,fileListComp},
        data(){
            const check_fuze_uids = (rule, value, callback) => {
                if(value.length===0){
                    callback(new Error('请选择任务负责人'));
                }else{
                    callback();
                }
            };
            const check_deal_uids = (rule, value, callback) => {
                if(value.length===0){
                    callback(new Error('请选择任务承办人'));
                }else{
                    callback();
                }
            };
            const check_round = (rule, value, callback) => {
                if(!value&&!this.task_rank){
                    callback(new Error('请选择任务上报频率'));
                }else{
                    callback();
                }
            };
            return{
                ruleForm:{
                    task_id:'',
                    task_title:'',
                    task_year:'',
                    time:'',
                    task_des:'',
                    file_ids:[],
                    task_type:'',
                    create_uid:{},
                    fuze_uids:[],
                    deal_uids:[],
                    round:'',
                    task_quality:''
                },
                oldRuleForm:{},
                rules:{
                    task_title: [{ required: true, message: '请输入任务名称', trigger: 'blur'}],
                    task_type:[{ required: true, message: '请选择任务类别', trigger: 'change' }],
                    task_year:[{ required: true, message: '请选择任务年度', trigger: 'change' }],
                    time:[{ required: true, message: '请选择建设周期',trigger: 'change' }],
                    task_quality: [{required: true, message: '请选择任务属性', trigger: 'change'}],
                    // fuze_uids:[{required: true,validator:check_fuze_uids, trigger: 'change'}],
                    // deal_uids:[{required: true,validator:check_deal_uids, trigger: 'change'}],
                },
                taskType:[
                    {value:1,label:'项目类'},
                    {value:2,label:'党建类'},
                    {value:3,label:'日常管理类'}
                ],
                taskQuality:[
                    {value: 1, label: '常规性'},
                    {value: 2, label: '临时性'},
                ],
                childList:[],
            }
        },
        computed:{
            ...mapState('task',[
                'history'
            ]),
            task_id() {
                return this.$store.state.task.history[this.$store.state.task.history.length-1].task_id
            },
            father_task_id() {
                return this.$store.state.task.history[this.$store.state.task.history.length-1].father_task_id
            },
            task_rank() {
                return this.$store.state.task.history[this.$store.state.task.history.length-1].task_rank
            },
            history_length() {
                return this.$store.state.task.history.length;
            },
        },
        methods:{
            ...mapMutations('task',[
                'SETHISTORT'
            ]),
            taskDetail(){
                this.$nextTick(()=>{
                    this.$refs.ruleForm.resetFields();
                })
                api.task.taskDetail({task_id:this.history[this.history.length-1].task_id}).then(res => {
                    this.ruleForm = {
                        task_id:'',
                        father_task_id:0,
                        task_rank:0,
                        task_title:'',
                        task_year:'',
                        time:'',
                        task_des:'',
                        file_ids:[],
                        task_type:'',
                        create_uid:{},
                        fuze_uids:[],
                        deal_uids:[],
                        round:'',
                        task_quality:''
                    };
                    if (res.data&&res.data.rows) {
                        if(res.data.rows.task_info){
                            let task_info = res.data.rows.task_info;
                            this.ruleForm.task_id = task_info.task_id;
                            this.ruleForm.father_task_id = task_info.father_task_id;
                            if(!isNaN(Number(task_info.task_rank))){
                                this.ruleForm.task_rank = Number(task_info.task_rank);
                            }
                            this.ruleForm.task_title = task_info.task_title;
                            this.ruleForm.task_year = task_info.task_year;
                            if(task_info.start_time&&task_info.end_time){
                                this.ruleForm.time=[
                                    task_info.start_time.slice(0,10),
                                    task_info.end_time.slice(0,10)
                                ]
                            }
                            if(task_info.task_des){
                                this.ruleForm.task_des=task_info.task_des;
                            }
                            if(myPublic.isJsonString(task_info.file_ids)){
                                this.ruleForm.file_ids=JSON.parse(task_info.file_ids)
                            }
                            if(myPublic.isJsonString(task_info.round)){
                                this.ruleForm.round=JSON.parse(task_info.round)
                            }
                            this.ruleForm.task_type=task_info.task_type;
                            this.ruleForm.task_quality = task_info.task_quality;
                            if(res.data.rows.user_info){
                                res.data.rows.user_info.forEach(user=>{
                                    if(user.task_id===this.ruleForm.task_id){
                                        if(user.user_type==1){
                                            this.ruleForm.create_uid=user
                                        }
                                        if(user.user_type==2){
                                            this.ruleForm.fuze_uids.push(user)
                                        }
                                        if(user.user_type==4){
                                            this.ruleForm.deal_uids.push(user)
                                        }
                                    }
                                })
                            }
                            this.oldRuleForm=JSON.parse(JSON.stringify(this.ruleForm));
                            this.childTaskList();
                        }
                    }
                })
            },
            childTaskList(){
                this.childList=[];
                api.task.childTaskList({father_task_id:this.task_id}).then(res=>{
                    if(res.data&&res.data.row){
                        this.childList=res.data.row.task_lists||[];
                        if(this.childList.length>0&&res.data.row.user_info&&res.data.row.user_info.length>0){
                            this.childList.forEach(item=>{
                                item.create_uid={};
                                item.fuze_uids=[];
                                item.deal_uids=[];
                                res.data.row.user_info.forEach(user=>{
                                    if(user.task_id===item.task_id){
                                        if(user.user_type==1){
                                            item.create_uid=user
                                        }
                                        if(user.user_type==2){
                                            item.fuze_uids.push(user)
                                        }
                                        if(user.user_type==4){
                                            item.deal_uids.push(user)
                                        }
                                    }
                                })
                            })
                        }
                    }
                })
            },
            submitForm(){
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.editApi().then(res=>{
                            if(res.reject){
                                this.$emit('back');
                            }
                        })
                    }
                })
            },
            editApi(){
                if(this.ruleForm.task_title.length>100){
                    this.$message({message:'任务名称不能大于100字!',type:'warning'});
                    return new Promise(function(reslove,reject){
                        reslove({reject:false});
                        reject('失败')
                    })
                }
                let param={
                    task_id:this.ruleForm.task_id,
                    father_task_id:this.ruleForm.father_task_id,
                    task_rank:this.ruleForm.task_rank,
                    task_title:this.ruleForm.task_title,
                    task_year:this.ruleForm.task_year,
                    task_des:this.ruleForm.task_des,
                    file_ids:JSON.stringify(this.ruleForm.file_ids),
                    task_type:this.ruleForm.task_type,
                    create_uid:this.ruleForm.create_uid.dingding.userid,
                    fuze_uids:this.ruleForm.fuze_uids.map(item=>item.dingding.userid).join(','),
                    deal_uids:this.ruleForm.deal_uids.map(item=>item.dingding.userid).join(','),
                    task_quality:this.ruleForm.task_quality
                }
                if(this.ruleForm.time&&this.ruleForm.time.length>0){
                    param.start_time=this.ruleForm.time[0];
                    param.end_time=this.ruleForm.time[1];
                }
                if (!param.task_rank) {
                    if (!this.initShowRound(this.ruleForm.round)) {
                        this.$message({message: '请选择任务上报频率', type: 'warning'});
                        return new Promise(function(reslove,reject){
                            reslove({reject:false});
                            reject('失败')
                        })
                    }
                    if (this.ruleForm.round.round_type === 'once') {
                        let round_time = this.ruleForm.round.child.date_time + ' 00:00:00',
                            start_time = param.start_time + ' 00:00:00',
                            end_time = param.end_time + ' 00:00:00';
                        if (round_time < start_time || round_time > end_time) {
                            this.$message({message: '自定义上报日期必须在任务起止时间以内!', type: 'warning'});
                            return new Promise(function(reslove,reject){
                                reslove({reject:false});
                                reject('失败')
                            })
                        }
                    }
                    param.round = JSON.stringify(this.ruleForm.round);
                }
                if(!param.task_rank){
                    param.round=JSON.stringify(this.ruleForm.round);
                    //判断是否修改的任务时间/承办人/循环方式, 如果有一样修改则edit_round为1
                    function initList(oldList=[],newList=[]){
                        oldList=new Set(oldList);
                        newList=new Set(newList);
                        let add = [];
                        let del = [];
                        add = Array.from(new Set([...newList].filter(x=>!oldList.has(x))));
                        del = Array.from(new Set([...oldList].filter(x=>!newList.has(x))));
                        return [...add,...del]
                    }
                    let old_round=JSON.stringify(this.oldRuleForm.round),
                        new_round=JSON.stringify(this.ruleForm.round),
                        old_deal_uids=this.oldRuleForm.deal_uids.map(item=>item.dingding.userid),
                        new_deal_uids=this.ruleForm.deal_uids.map(item=>item.dingding.userid),
                        old_start_time=this.oldRuleForm.time[0],
                        old_end_time=this.oldRuleForm.time[1],
                        new_start_time=this.ruleForm.time[0],
                        new_end_time=this.ruleForm.time[1];
                    if(old_round!==new_round||initList(old_deal_uids,new_deal_uids).length>0||old_start_time!==new_start_time||old_end_time!==new_end_time){
                        param.edit_round=1;
                    }else{
                        param.edit_round=0;
                    }
                    console.log(initList(old_deal_uids,new_deal_uids),'编辑任务时承办人id变化');
                }
                return api.task.addTask(param).then(res=>{
                    if(res.data.state==1){
                        this.$message({
                            message: '任务编辑成功',
                            type: 'success'
                        });
                        return {reject:true};
                    }else{
                        return {reject:false};
                    }
                })
            },
            //选择人员
            choosePeople(data){
                peoplePicker.peoplePicker(this.ruleForm[data].map(item=>item.dingding.userid), (res) => {
                    let users=[];
                    res.users.forEach(user=>{
                        if(user.emplId){
                            users.push(
                                {
                                    dingding:{
                                        avatar:user.avatar,
                                        nickname:user.name,
                                        userid:user.emplId
                                    }
                                }
                            )
                        }
                    });
                    this.ruleForm[data]=users;
                },true)
            },
            roundChange(round){
                this.ruleForm.round=round;
            },
            addorEditChild(type,child){
                let change=[];
                for(let item in this.ruleForm){
                    if(item==='fuze_uids'){
                        if(JSON.stringify(this.ruleForm.fuze_uids.map(user=>user.dingding.userid))!==JSON.stringify(this.oldRuleForm.fuze_uids.map(user=>user.dingding.userid))){
                            change.push({name:item,new:this.ruleForm.fuze_uids.map(user=>user.dingding.userid),old:this.oldRuleForm.fuze_uids.map(user=>user.dingding.userid)});
                        }
                    }else if(item==='deal_uids'){
                        if(JSON.stringify(this.ruleForm.deal_uids.map(user=>user.dingding.userid))!==JSON.stringify(this.oldRuleForm.deal_uids.map(user=>user.dingding.userid))){
                            change.push({name:item,new:this.ruleForm.deal_uids.map(user=>user.dingding.userid),old:this.oldRuleForm.deal_uids.map(user=>user.dingding.userid)});
                        }
                    }else{
                        if(JSON.stringify(this.ruleForm[item])!==JSON.stringify(this.oldRuleForm[item])){
                            change.push({name:item,new:this.ruleForm[item],old:this.oldRuleForm[item]});
                        }
                    }
                }
                let self = this;
                function set_history(){
                    if(type==='add'){
                        self.SETHISTORT({key:'push',value:{type:'add',task_id:0,father_task_id:self.ruleForm.task_id,task_rank:self.ruleForm.task_rank+1}})
                    }
                    if(type==='edit'){
                        self.SETHISTORT({key:'push',value:{type:'edit',task_id:child.task_id,father_task_id:child.father_task_id,task_rank:child.task_rank}})
                    }
                }
                console.log(JSON.stringify(change),'点击新建子任务时当前任务有哪些信息发生了变动');
                if(change.length>0){
                    const h = this.$createElement;
                    this.$msgbox({
                        title: '提示',
                        message: h('p', null, '是否保存已修改的任务内容?'),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.$refs.ruleForm.validate((valid) => {
                                    if (valid) {
                                        this.editApi().then(res=>{
                                            if(res.reject){
                                                set_history();
                                            }
                                        });
                                    }
                                })
                                done();
                            } else {
                                done();
                            }
                        }
                    }).catch(() => {
                        set_history();
                    });
                }else{
                    set_history();
                }
            },
            //上传附件
            updateFile(){
                dingDisk.dingDiskUpdate(res=>{
                    this.ruleForm.file_ids=[...this.ruleForm.file_ids,...res.data];
                })
            },
            //预览附件
            previewFile(file_info){
                dingDisk.previewFile(file_info)
            },
            //修改名称后的附件数组
            editNameBack(file_list) {
                this.ruleForm.file_ids = file_list;
            },
        },
        watch:{
            'history_length':{
                handler:function(val){
                    this.taskDetail();
                    // console.log(this.history,'弹窗跳转流程')
                },
                immediate:true
            }
        }
    }
</script>

<style lang="less">
.edit_task_form{
    .child{
        padding-left: 72px;
        height:40px;
        display:flex;
        align-items: center;
        line-height:14px;
        span{
            margin-right: 14px;
        }
    }
    .table{
        padding-left: 30px;
    }
    .item.round{
        /deep/ .el-form-item__label:before{
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
        }
    }
    .item.users{
        span.names{
            border-radius: 5px;
            position:relative;
            padding-right:15px;
            &:hover{
                i{
                    opacity: 1;
                }
            }
            i{
                position:absolute;
                right:1px;
                top:-3px;
                cursor: pointer;
                opacity: 0;
                transition:all 0.3s;
                &:hover{
                    color:#3396fb;
                }
            }
        }
    }
}
</style>