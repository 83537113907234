<template>
    <div class="add_register" v-if="task_info.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1||task_power.power.register">
        <div v-if="enter==1" @click="open">任务督办</div>
        <el-button size="small" type="primary" class="button_98px" v-if="enter==2" @click="open">任务督办</el-button>
        <el-dialog title="任务督办" :visible.sync="show" width="500px" append-to-body>
            <div class="content add_register_content">
                <el-input type="textarea" :rows="4" placeholder="请填写督办内容" v-model="content"></el-input>
            </div>
            <div class="button add_register_button">
                <el-button class="button_88px" size="small" type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';

    export default {
        props: ['task_info', 'enter'],
        data() {
            return {
                show: false, content: ''
            }
        },
        computed:{
            task_power(){
                return this.$store.state.user.current_user.task_power
            }
        },
        methods: {
            open() {
                this.show = true;
                this.content = ''
            },
            save() {
                if (!this.content) {
                    this.$message({message: '请填写督办内容!', type: 'warning'})
                    return;
                }
                api.task.taskRegister({
                    task_id: this.task_info.task_id,
                    content: this.content,
                    user_id: this.$store.state.user.current_user.userid
                }).then(res => {
                    if (res.data.state == 1) {
                        this.$message({
                            message: '任务督办成功',
                            type: 'success'
                        });
                        this.show = false;
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .add_register_content {
        padding: 20px;

        /deep/ .el-textarea .el-textarea__inner {
            resize: none;
        }
    }
    .button.add_register_button{
        border: none!important;
    }
</style>