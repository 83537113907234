<template>
    <div class="choose_round">
        <el-popover placement="right-end" width="340" trigger="click" :visible-arrow="false" v-model="show"
                    @after-leave="show=false"
                    popper-class="child_popover add_payment_popover choose_round_popover">
            <div class="content">
                <div class="header">
                    <div class="icon" @click="show=false">
                        <img class="default" src="../../../assets/img/del.png" alt="">
                        <img class="hover" src="../../../assets/img/del_hover.png" alt="">
                    </div>
                    <h4>上报频率</h4>
                </div>
                <div class="choose_round_content">
                    <div class="main_content">
                        <div class="radio_group">
                            <div :class="{radio:true,active:showTab===1}" @click="typeChange(1)">
                                <div class="icon">
                                    <img class="default" src="../../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">周</div>
                            </div>
                            <div :class="{radio:true,active:showTab===2}" @click="typeChange(2)">
                                <div class="icon">
                                    <img class="default" src="../../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">月</div>
                            </div>
                            <div :class="{radio:true,active:showTab===3}" @click="typeChange(3)">
                                <div class="icon">
                                    <img class="default" src="../../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">双月</div>
                            </div>
                            <div :class="{radio:true,active:showTab===4}" @click="typeChange(4)">
                                <div class="icon">
                                    <img class="default" src="../../../assets/img/radio.png" alt="">
                                    <img class="active" src="../../../assets/img/radio_active.png" alt="">
                                </div>
                                <div class="label">季</div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="radio_content">
                            <!--周循环-->
                            <div class="week_box" v-if="showTab===1">
                                <div class="week_item" v-for="(item,index) in week" :key="index+1"
                                     :class="{active:item.value===round.child.day_of_week}"
                                     @click="round.child.day_of_week=item.value">
                                    {{ item.label }}
                                </div>
                            </div>
                            <!--月循环-->
                            <div class="input_box" v-if="showTab===2">
                                每月第：
                                <el-input-number v-model="round.child.day_of_month" :validate-event="false"
                                                 class="number_input"
                                                 :min="1" :max="31" :precision="0" :step="1"
                                                 :controls="false"></el-input-number>
                                <span style="margin-left: 5px;">日</span>
                            </div>
                            <!--单双月循环-->
                            <div class="week_box" v-if="showTab===3">
                                <div class="week_item" :class="{active:round.child.type==='single'}"
                                     @click="round.child.type='single'">单
                                </div>
                                <div class="week_item" :class="{active:round.child.type==='double'}"
                                     @click="round.child.type='double'">双
                                </div>
                            </div>
                            <div class="input_box" v-if="showTab===3">
                                每{{ round.child.type === 'single' ? '单' : round.child.type === 'double' ? '双' : '' }}月第：
                                <el-input-number v-model="round.child.day_of_month" :validate-event="false"
                                                 class="number_input"
                                                 :min="1" :max="31" :precision="0" :step="1"
                                                 :controls="false"></el-input-number>
                                <span style="margin-left: 5px;">日</span>
                            </div>
                            <!--季循环-->
                            <div class="input_box" v-if="showTab===4">
                                <span style="flex-shrink: 0;">上报日期：</span>
                                <el-date-picker size="mini" v-model="round.child.date" type="date"
                                                value-format="yyyy-MM-dd" placeholder="请选择开始日期"
                                                :clearable="false"></el-date-picker>
                            </div>
                            <!--自定义-->
                            <div class="line" v-if="showTab!==5"></div>
                            <div class="round_5" :class="{active:showTab===5}">
                                <div class="round_title" @click="typeChange(5)">自定义上报日期</div>
                                <el-date-picker size="mini" v-model="round.child.date_time" type="date"
                                                value-format="yyyy-MM-dd" placeholder="请选择上报日期" :clearable="false"
                                                style="width:100%;" v-if="showTab===5"></el-date-picker>
                            </div>
                            <div class="text">{{ initShowRound(round) }}</div>
                        </div>
                    </div>
                    <div class="button">
                        <el-button class="button_78px" size="mini" type="primary" @click="save">保存</el-button>
                    </div>
                </div>
            </div>
            <el-button slot="reference" type="text">选择上报频率</el-button>
        </el-popover>
    </div>
</template>

<script>
import computedData from '@/mixins/computedData.js';

export default {
    props: ['parent_task'],//父组件传递过来的round
    mixins: [computedData],
    data() {
        return {
            /**
             * round:
             {"round_type":"week","child":{"day_of_week":1}}   //周循环 day_of_week：每周几号循环，可选：0-6  分别表示  周日-周六
             {"round_type":"month","child":{"day_of_month":1,"type":"single"}}//月循环  day_of_month：每月几号循环, 可选：1-31   single：单月，double：双月,every: 每月
             {"round_type":"quarter","child":{"date":'2020-05-31'}}//按季循环  date：循环开始日期
             {"round_type":"once","child":{"date_time":'2020-06-28'}}//循环一次  date__time：循环提醒的时间（默认任务的结束时间）循环提醒的时间不能小于任务开始时间或者大于任务结束时间
             */
            show: false, round: {round_type: 'week', child: {day_of_week: 1}},
            week: [
                {value: 1, label: '周一'},
                {value: 2, label: '周二'},
                {value: 3, label: '周三'},
                {value: 4, label: '周四'},
                {value: 5, label: '周五'},
                {value: 6, label: '周六'},
                {value: 0, label: '周日'},
            ]
        };
    },
    computed: {
        showTab() {
            if (this.round.round_type === 'week') {
                return 1;
            }
            if (this.round.round_type === 'month' && this.round.child.type === 'every') {
                return 2;
            }
            if (this.round.round_type === 'month' && (this.round.child.type === 'single' || this.round.child.type === 'double')) {
                return 3;
            }
            if (this.round.round_type === 'quarter') {
                return 4;
            }
            if (this.round.round_type === 'once') {
                return 5;
            }
        }
    },
    methods: {
        typeChange(e) {
            if (this.showTab === e) return;
            switch (e) {
                case 1:
                    this.round = {
                        round_type: 'week',
                        child: {
                            day_of_week: 1
                        }
                    };
                    break;
                case 2:
                    this.round = {
                        round_type: 'month',
                        child: {
                            day_of_month: 1,
                            type: 'every'
                        }
                    };
                    break;
                case 3:
                    this.round = {
                        round_type: 'month',
                        child: {
                            day_of_month: 1,
                            type: 'single'
                        }
                    };
                    break;
                case 4:
                    this.round = {
                        round_type: 'quarter',
                        child: {
                            date: ''
                        }
                    };
                    break;
                case 5:
                    this.round = {
                        round_type: 'once',
                        child: {
                            date_time: ''
                        }
                    };
                    if (this.parent_task.time) {
                        this.$set(this.round.child, 'date_time', this.parent_task.time[1]);
                    }
                    break;
            }
        },
        save() {
            if (!this.initShowRound(this.round)) {
                this.$message({message: '请选择任务上报频率', type: 'warning'});
                return;
            }
            this.show = false;
            this.$emit('roundChange', this.round);
        }
    },
    watch: {
        'show': {
            handler: function (val) {
                if (val) {
                    if (this.parent_task.round) {
                        this.round = JSON.parse(JSON.stringify(this.parent_task.round));
                    } else {
                        this.round = {round_type: 'week', child: {day_of_week: 1}};
                    }
                }
            },
            immediate: true
        },
        'parent_task.time': {
            handler: function (val) {
                if (val) {
                    //如果已经设置了循环规则并且为自定义填报的情况下根据任务起止时间, 填报时间改为任务结束时间
                    if (this.parent_task.round && this.parent_task.round.round_type != 'once') {
                        return;
                    }
                    this.round = {
                        round_type: 'once',
                        child: {
                            date_time: this.parent_task.time[1]
                        }
                    };
                    this.$emit('roundChange', this.round);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="less">
.choose_round_popover {
    .choose_round_content {
        .main_content {
            .radio_group {
                height: 55px;
            }

            .line {
                border-top: 1px solid #eee;
                margin: 0 30px;
            }

            .radio_content {
                height: 150px;

                .week_box {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 30px;
                    padding-top: 15px;

                    .week_item {
                        flex: 0 0 50px;
                        line-height: 20px;
                        border-radius: 5px;
                        border: 1px solid #ccc;
                        color: #666;
                        cursor: pointer;
                        transition: all 0.3s;
                        text-align: center;
                        font-size: 12px;
                        margin-bottom: 10px;
                        margin-right: 20px;

                        &.active {
                            background: #3396fb;
                            color: #fff;
                            border-color: #3396fb;
                        }
                    }
                }

                .input_box {
                    font-size: 12px;
                    color: #666;
                    padding: 0 30px;
                    display: flex;
                    height: 50px;
                    align-items: center;

                    .number_input {
                        width: 60px;
                        height: 25px;
                        font-size: inherit;
                        color: inherit;

                        div.el-input {
                            height: 100%;
                            font-size: inherit;
                            color: inherit;
                            display: flex;
                            align-items: center;

                            input {
                                height: 100%;
                                font-size: inherit;
                                color: inherit;
                                text-align: center;
                            }
                        }
                    }
                }

                .text {
                    padding: 5px 30px 0 30px;
                    font-size: 12px;
                    color: #999;
                }

                .round_5 {
                    margin: 0 30px;

                    &.active {
                        .round_title {
                            color: #3396fb;
                            margin-bottom: 10px;
                        }
                    }

                    .round_title {
                        padding-top: 10px;
                        line-height: 1em;
                        cursor: pointer;
                        transition: color 0.3s;

                        &:hover {
                            color: #3396fb;
                        }
                    }
                }
            }

            .input_box {
                .el-date-editor.el-input.el-input--mini.el-input--prefix.el-input--suffix.el-date-editor--date {
                    .el-input__icon.el-input__validateIcon.el-icon-circle-close {
                        display: none;
                    }
                }
            }
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
        }
    }
}
</style>