<template>
    <!--v-if="!task_info.task_rank&&(task_info.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1||task_info.deal_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1)">-->
    <!--以前任务上报负责人和承办人都可以,7.5日改为只能承办人上报, 为了实现承办人分开上报更改的.-->
    <div class="report_list"
         v-if="!task_info.task_rank&&(task_info.deal_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1||task_info.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1)">
        <el-popover :placement="enter==1?'left-start':'top'" width="222" trigger="click" :visible-arrow="false"
                    v-model="show" @after-leave="show=false"
                    popper-class="child_popover add_payment_popover report_list_popover">
            <div class="content">
                <div class="header">
                    <div class="icon" @click="show=false">
                        <img class="default" src="../../../assets/img/del.png" alt="">
                        <img class="hover" src="../../../assets/img/del_hover.png" alt="">
                    </div>
                    <h4>选择上报时间</h4>
                </div>
                <div class="report_list_content">
                    <div class="item" v-if="list&&list.length>0" v-for="(item,index) in list" :key="index+1"
                         @click="chooseReport(item.report_id,item.report_time)">
                        截至{{item.show_time}}
                    </div>
                    <div class="no_data" v-if="list.length===0">暂无需要上报时间</div>
                </div>
            </div>
            <div slot="reference" v-if="enter==1">任务上报</div>
            <el-button slot="reference" size="small" type="primary" class="button_98px" v-if="enter==2">任务上报</el-button>
        </el-popover>
        <addReport :report_id="report_id" :report_time="report_time" :task_info="task_info" @refresh="$emit('refresh')"
                   @clearId="clearId"></addReport>
        <editReport ref="editReport"></editReport>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import addReport from '@c/task/report/add_report.vue';
    import editReport from '@c/task/report/edit_report.vue';

    export default {
        props: ['task_info', 'enter'],//enter:1=>从列表上进入, enter:2=>从任务详情中进入
        components: {addReport, editReport},
        data() {
            return {
                show: false, list: [], report_id: '', report_time: ''
            }
        },
        methods: {
            noReportTime() {
                api.task.noReportTime({
                    task_id: this.task_info.task_id,
                    task_quality: this.task_info.task_quality,
                    page: 1,
                    pagesize: 999
                }).then(res => {
                    if (res.data) {
                        this.list = res.data.lists || [];
                        this.list.forEach((item, index) => {
                            if (!item.report_time) {
                                this.list.splice(index, 1);
                            } else {
                                item.show_time = item.report_time.slice(0, 10)
                            }
                        })
                    }
                })
            },
            chooseReport(report_id, report_time) {
                //临时性任务需要请求一下是否有上报过,如果有则修改之前的上报信息
                if(this.task_info.task_quality==2){
                    api.task.reportDeatil({task_id: this.task_info.task_id, report_id: report_id}).then(res => {
                        let report_info = {};
                        if (res.data && res.data.rows) {
                            report_info = res.data.rows;
                            if (report_info.id) {
                                this.$refs.editReport.report_detail = report_info;
                                this.$refs.editReport.open();
                            } else {
                                this.report_id = report_id;
                                this.report_time = report_time;
                            }
                            this.show = false;
                        }
                    })
                }else{//常规性任务直接上报
                    this.report_id = report_id;
                    this.report_time = report_time;
                    this.show = false;
                }
            },
            //任务上报弹出框关闭后清空report_id
            clearId() {
                this.report_id = '';
                this.report_time = '';
            }
        },
        watch: {
            'show': {
                handler: function (val) {
                    if (val) {
                        this.noReportTime();
                    }
                },
                immediate: true
            }
        }
    }
</script>

<style lang="less">
    .report_list_popover {
        .content {
            display: flex;
            flex-direction: column;

            .report_list_content {
                flex: 0 0 190px;
                min-height: 0;
                overflow: overlay;

                .item {
                    line-height: 30px;
                    text-align: center;
                    transition: all 0.3s;
                    cursor: pointer;

                    &:hover {
                        background: #f4f8ff;
                        color: #3396fb;
                    }
                }
                .no_data{
                    text-align: center;
                    line-height: 30px;
                    color:#999;
                }
            }
        }
    }
</style>