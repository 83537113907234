//钉盘 上传,预览
import app from "@/utils/ddready";
import store from "@/vuex/index";
import api from "@/api/methods/index";
import container from "./container";
import { Message } from "element-ui";

export default {
	dingDiskUpdate(callback, max = 9) {
		// if (container.dd) {
		// 	api.login.grantCustomSpace({ type: "add" }).then((res) => {
		// 		if (res.data.state == 1) {
		// 			dd.ready(function() {
		// 				dd.biz.util.uploadAttachment({
		// 					image: {
		// 						multiple: true,
		// 						compress: false,
		// 						max: max,
		// 						spaceId: res.data.data.space_id,
		// 					},
		// 					space: {
		// 						corpId: store.state.user.corp.corp_id,
		// 						spaceId: res.data.data.space_id,
		// 						isCopy: 0,
		// 						max: max,
		// 					},
		// 					file: { spaceId: res.data.data.space_id, max: max },
		// 					types: ["photo", "file"], //PC端支持["photo","file","space"]
		// 					onSuccess: function(result) {
		// 						console.log(result, "result");
		// 						//onSuccess将在文件上传成功之后调用
		// 						/*
		//                    {
		//                       type:'', // 用户选择了哪种文件类型 ，image（图片）、file（手机文件）、space（钉盘文件）
		//                       data: [
		//                          {
		//                            spaceId: "232323",
		//                            fileId: "DzzzzzzNqZY",
		//                            fileName: "审批流程.docx",
		//                            fileSize: 1024,
		//                            fileType: "docx"
		//                         },
		//                         {
		//                            spaceId: "232323",
		//                            fileId: "DzzzzzzNqZY",
		//                            fileName: "审批流程1.pdf",
		//                            fileSize: 1024,
		//                            fileType: "pdf"
		//                         },
		//                         {
		//                            spaceId: "232323",
		//                            fileId: "DzzzzzzNqZY",
		//                            fileName: "审批流程3.pptx",
		//                            fileSize: 1024,
		//                            fileType: "pptx"
		//                          }
		//                       ]

		//                    }
		//                     */
		// 						callback(result);
		// 					},
		// 					onFail: function(err) {
		// 						console.log(err, "上传附件错误");
		// 						if (err.errorCode != -1) {
		// 							if (typeof err == "object") {
		// 								err["corpId"] = store.state.user.corp.corp_id;
		// 							}
		// 							app.ready_login(function() {});
		// 						}
		// 					},
		// 				});
		// 			});
		// 		}
		// 	});
		// } else if (container.browser) {
		const input_comp = document.createElement("input");
		input_comp.type = "file";
		input_comp.multiple = false;
		input_comp.addEventListener("change", async (e) => {
			const files = e.target.files;
			if (files.length > max) {
				Message({ type: "warning", message: "文件数量超过限制，请重新选择！" });
				return;
			}

			const msg = await api.login.upload({ "file[]": files[0] });
			if (msg.data.state == 1) {
				callback({
					type: "web",
					data: msg.data.row.map((it) => ({ ...it, fileName: it.name })),
				});
			}
		});
		input_comp.click();
		// }
	},
	previewFile(file_info) {
		if (file_info.platform_type == "web") {
			window.open(`/${file_info.url}`);
		} else {
			window.open(`/uploads/dingding/${file_info.fileId}-${file_info.fileName}`);
		}
	},
};
