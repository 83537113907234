<template>
    <div class="task_dialog">
        <el-button size="small" type="primary" @click="open" class="button_98px" v-if="enter===1">新建任务</el-button>
        <div v-if="enter===2" @click="open">编辑任务</div>
        <div v-if="enter===3&&!task_title" @click="open">查看任务</div>
        <div class="task_title_list" v-if="enter===3&&task_title" @click="open">{{task_title}}</div>
        <el-dialog :title="history[history.length-1].type==='add'?'新建任务':history[history.length-1].type==='edit'?'编辑任务':history[history.length-1].type==='detail'?'任务详情':''"
                   :visible.sync="show" width="1105px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" custom-class="task_dialog" @closed="closed=true;$emit('refresh',true);">
            <div class="back" @click="back" v-if="history.length>1">
                <img class="default" src="../../assets/img/back.png" alt="">
                <img class="hover" src="../../assets/img/back_hover.png" alt="">
            </div>
            <addTask v-if="history[history.length-1].type==='add'&&!closed" @back="back"></addTask>
            <editTask v-if="history[history.length-1].type==='edit'&&!closed" @back="back"></editTask>
            <taskDetail v-if="history[history.length-1].type==='detail'&&!closed" @back="back"></taskDetail>
        </el-dialog>
    </div>
</template>

<script>
    import addTask from '@c/task/add_task.vue';
    import editTask from '@c/task/edit_task.vue';
    import taskDetail from '@c/task/task_detail.vue';
    import {mapState,mapMutations} from 'vuex';
    export default {
        components:{addTask,editTask,taskDetail},
        props:{
            enter:{
                type:Number,
                require:true,
            },//打开弹出入口,确定显示什么按钮,1:新建任务,2:编辑任务,3:查看任务
            task_info:{
                type:Object,
            },
            task_title:{
                type:String,
            }//新增点击任务标题可以跳出弹窗,点击任务标题的enter为3
        },
        data(){
            return{
                show:false,closed:true,//弹窗是否关闭
            }
        },
        computed:{
            ...mapState('task',[
                'history',
            ]),
        },
        methods:{
            ...mapMutations('task',[
                'SETHISTORT'
            ]),
            open(){
                switch (this.enter) {
                    case 1:
                        this.SETHISTORT({key:'cover',value:[{
                            type:'add',
                            task_id:0,
                            father_task_id:0,
                            task_rank:0
                        }]});
                        break;
                    case 2:
                        this.SETHISTORT({key:'cover',value:[{
                            type:'edit',
                            task_id:this.task_info.task_id,
                            father_task_id:this.task_info.father_task_id,
                            task_rank:this.task_info.task_rank
                        }]})
                        break;
                    case 3:
                        this.SETHISTORT({key:'cover',value:[{
                            type:'detail',
                            task_id:this.task_info.task_id,
                            father_task_id:this.task_info.father_task_id,
                            task_rank:this.task_info.task_rank
                        }]})
                        break;
                }
                this.show=true;
                this.closed=false;
            },
            //跳转回上一流程
            back(){
                if (this.history.length > 1) {
                    // this.$emit('back');
                    this.SETHISTORT({key:'splice',value:this.history[this.history.length-1]})
                } else {
                    // this.$emit('close');
                    this.show=false;
                }
            },
            // close(){
            //     this.show=false;
            // }
        },
        watch:{
            '$store.state.task.show':{
                handler:function (val){
                    if(val){
                        this.show=true;
                        this.closed=false;
                        this.$store.commit('task/SETSHOW',false);
                    }
                },
                immediate:true
            }
        }
    }
</script>

<style lang="less">
.task_dialog{
    .task_title_list{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .el-dialog__body{
        position:relative;
        .back{
            position:absolute;
            width:20px;
            height:40px;
            left:30px;
            top:-40px;
            cursor: pointer;
            display:flex;
            align-items: center;
            img{
                &.default{
                    display:inline-block;
                }
                &.hover{
                    display:none
                }
            }
            &:hover{
                img{
                    &.default{
                        display:none
                    }
                    &.hover{
                        display:inline-block;
                    }
                }
            }
        }
    }
}
</style>