<template>
    <div class="edit_report">
        <div class="report_content" v-if="enter==='text'" v-html="report_info.new_content" @click="open(report_info)"></div>
        <el-button type="text" v-if="enter==='btn'" @click="open(report_info)">编辑</el-button>
        <el-dialog title="编辑上报" :visible.sync="show" width="500px" append-to-body>
            <div class="content">
                <el-input type="textarea" :rows="4" placeholder="请填写任务上报内容" v-model="content"></el-input>
                <div class="files" v-if="file_ids&&file_ids.length>0">
                    <fileListComp :file_list="file_ids" @change="editNameBack" />
                </div>
                <div class="add">
                    <el-button size="mini" type="text" @click="updateFile">上传附件</el-button>
                </div>
            </div>
            <div class="button">
                <el-button class="button_88px" size="small" type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import dingDisk from '@/utils/ding_disk';
    import myPublic from '@/utils/public.js';
    import fileListComp from '@/components/file/index.vue'

    export default {
        components:{fileListComp},
        props: {
            enter:String,
            report_info: Object
        },
        data() {
            return {
                show: false, content: '', file_ids: [],report_detail:{}
            }
        },
        methods: {
            open() {
                if(this.enter === 'btn'){
                    this.report_detail = JSON.parse(JSON.stringify(this.report_info));
                }
                if(this.report_detail.content){
                    this.content = JSON.parse(JSON.stringify(this.report_detail.content))
                }else{
                    this.content='';
                }
                let file_ids=JSON.parse(JSON.stringify(this.report_detail.file_ids));
                if(file_ids){
                    if (myPublic.isJsonString(file_ids)){
                        this.file_ids=JSON.parse(file_ids)
                    }else{
                        this.file_ids=file_ids
                    }
                }else{
                    this.file_ids=[];
                }
                this.show = true;
            },
            save() {
                if (!this.content) {
                    this.$message({message: '请填写任务上报内容!', type: 'warning'});
                    return;
                }
                let param = {
                    id:this.report_detail.id,
                    task_id: this.report_detail.task_id,
                    report_uid: this.report_detail.report_uid,
                    report_id: this.report_detail.report_id,
                    user_type:this.report_detail.user_type,
                    content: this.content,
                    file_ids: JSON.stringify(this.file_ids)
                }
                api.task.taskReport(param).then(res => {
                    if (res.data.state == 1) {
                        this.$message({
                            message: '编辑任务上报成功',
                            type: 'success'
                        });
                        if(this.enter==='btn'){
                            let regN = /\n/g;
                            let regR = /\r/g;
                            this.$set(this.report_info,'content',this.content);
                            this.$set(this.report_info,'new_content',this.content.replace(regN, "<br />").replace(regR, "<br />"));
                            this.$set(this.report_info,'file_ids',this.file_ids);
                        }
                        this.show=false;
                    }
                })
            },
            //上传附件
            updateFile() {
                dingDisk.dingDiskUpdate(res => {
                    this.file_ids = [...this.file_ids, ...res.data];
                })
            },
            //预览附件
            previewFile(file_info) {
                dingDisk.previewFile(file_info)
            },
            //修改名称后的附件数组
            editNameBack(file_list) {
                this.file_ids = file_list;
            },
        }
    }
</script>

<style scoped lang="less">
    div.edit_report {
        .report_content {
            text-align: left;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    /deep/ div.el-dialog {
        .content {
            padding: 20px 20px 10px 20px;
            flex: 1;
            min-height: 0;
            overflow-y: overlay;

            .title {
                padding: 10px 0;
            }

            .el-textarea .el-textarea__inner {
                resize: none;
            }

            .files {
                padding-top: 10px;

                .item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    span {
                        flex: 1;
                        min-width: 0;
                        font-size: 12px;
                        cursor: pointer;
                        transition: color 0.3s;

                        &:hover {
                            color: #3396fb;
                        }
                    }

                    div.icon_del {
                        flex-shrink: 0;
                        width: 12px;
                        height: 12px;
                        margin-left: 10px;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        cursor: pointer;
                        background-image: url("../../../assets/img/del.png");

                        &:hover {
                            background-image: url("../../../assets/img/del_hover.png");
                        }
                    }
                    .icon {
                        width: 12px;
                        height: 12px;
                        position: relative;
                        cursor: pointer;

                        img {
                            width: 12px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;

                            &.default {
                                opacity: 1;
                            }

                            &.hover {
                                opacity: 0;
                            }
                        }

                        &:hover {
                            img {
                                &.default {
                                    opacity: 0;
                                }

                                &.hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .button {
            border: none !important;
        }
    }
</style>