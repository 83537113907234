<template>
    <div class="delete_task">
        <div v-if="enter==1" @click="delTask">删除任务</div>
        <el-button size="small" type="primary" class="button_98px" v-if="enter==2" @click="delTask">删除任务</el-button>
        <el-button type="text" v-if="enter==3" @click="delTask">删除</el-button>
    </div>
</template>

<script>
    import api from '@/api/methods/index';

    export default {
        props: ['task_info', 'enter'],
        methods: {
            delTask() {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, '确定要删除该任务吗?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            api.task.delTask({task_id: this.task_info.task_id}).then(res => {
                                if (res.data.state == 1) {
                                    this.$message({
                                        message: '任务删除成功',
                                        type: 'success'
                                    });
                                    this.$emit('refresh');
                                }
                            })
                            done();
                        } else {
                            done();
                        }
                    }
                }).catch(() => {

                });
            }
        }
    }
</script>

<style scoped>

</style>