<template>
    <div class="detail_box">
        <div class="detail">
            <div class="content">
                <div class="title">任务基础信息</div>
                <div class="detail_item items">
                    <div class="item long task_title">
                        <div class="left">任务名称</div>
                        <div class="right">{{ detail.task_title }}</div>
                        <div class="task_appraise"
                             v-if="detail.task_appraise&&(detail.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1||task_power.power.register)"
                             :style="{backgroundImage:'url('+initTaskAppraiseImg(detail.task_appraise)+')'}"></div>
                        <!--$store.state.user.current_user.dignity==2||$store.state.user.current_user.dignity==3-->
                    </div>
                    <div class="item">
                        <div class="left">任务类别</div>
                        <div class="right">{{ initTaskType(detail.task_type) }}</div>
                    </div>
                    <div class="item">
                        <div class="left">任务年度</div>
                        <div class="right">{{ detail.task_year }}年度</div>
                    </div>
                    <div class="item">
                        <div class="left">任务起止时间</div>
                        <div class="right">
                            <span v-if="detail.time">{{ detail.time[0] }} - {{ detail.time[1] }}</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">任务属性</div>
                        <div class="right">{{ initTaskQuality(detail.task_quality) }}</div>
                    </div>
                    <div class="item long">
                        <div class="left">任务描述</div>
                        <div class="right file_box">
                            <div class="text" v-html="detail.task_des"
                                 :style="{marginBottom:detail.file_ids&&detail.file_ids.length>0?'10px':0}"></div>
                            <div class="file" v-for="(item,index) in detail.file_ids" :key="index">
                                <div class="text"><span @click="previewFile(item)">{{ item.fileName }}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">任务负责人</div>
                        <div class="right">
                            <span v-if="detail.fuze_uids&&detail.fuze_uids.length>0">{{
                                    detail.fuze_uids.map(item => item.dingding.nickname).join('、')
                                }}</span>
                            <span v-else>无</span>
                        </div>
                    </div>
                    <div class="item">
                        <div class="left">任务承办人</div>
                        <div class="right">
                            <span v-if="detail.deal_uids&&detail.deal_uids.length>0">{{
                                    detail.deal_uids.map(item => item.dingding.nickname).join('、')
                                }}</span>
                            <span v-else>无</span>
                        </div>
                    </div>
                    <div class="item long" v-if="detail.show_round">
                        <div class="left">任务上报频率</div>
                        <div class="right">{{ detail.show_round }}</div>
                    </div>
                </div>
                <div class="all_no_report" v-if="all_no_report&&all_no_report.length>0">
                    <p>任务未上报人员：</p>
                    <div v-for="(v,k) in all_no_report" :key="'all_no_report'+k">{{
                            v.date
                        }}期未上报人员：{{ v.names.map(a => a.dingding).map(b => b.nickname).join('、') }}
                    </div>
                </div>
                <div class="detail_item table report_table">
                    <div class="title">
                        <span>上报列表</span>
                        <div class="report_time_select">
                            <span>上报周期：</span>
                            <el-select v-model="report_time" style="width: 150px" placeholder="默认所有周期" clearable
                                       size="small" @change="reportLists">
                                <el-option v-for="(item,index) in reportTime" :key="index+1" :label="item"
                                           :value="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-table :data="reportList">
                        <el-table-column label="任务上报人" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.deal_user_info">{{
                                        scope.row.deal_user_info.nickname || ''
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="任务上报时间" align="center"></el-table-column>
                        <el-table-column prop="show_report_time" label="上报周期" align="center"></el-table-column>
                        <el-table-column label="上报内容" align="center">
                            <template slot-scope="scope">
                                <!--<editReport enter="text" :report_info="scope.row"></editReport>-->
                                <div v-html="scope.row.new_content" style="text-align: left"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="附件" align="center">
                            <template slot-scope="scope">
                                <div class="files" v-if="scope.row.file_ids&&scope.row.file_ids.length>0">
                                    <div class="item" v-for="(item,index) in scope.row.file_ids" :key="index+1"
                                         @click="previewFile(item)">{{ item.fileName }}
                                    </div>
                                </div>
                                <div v-else>-</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="上报评价" align="center" width="130px">
                            <template slot-scope="scope">
                                <!--<div v-if="scope.row.user_type==4">-->
                                <el-popover placement="bottom" width="100" :open-delay="200" trigger="hover"
                                            :visible-arrow="false" popper-class="operation_popover"
                                            transition="el-zoom-in-top"
                                            :disabled="detail.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)===-1">
                                    <ul>
                                        <li @click="taskRrportAppraise(scope.row,1)">提前完成</li>
                                        <li @click="taskRrportAppraise(scope.row,2)">按时完成</li>
                                        <li @click="taskRrportAppraise(scope.row,3)">延期完成</li>
                                        <li @click="taskRrportAppraise(scope.row,4)">未完成</li>
                                    </ul>
                                    <div slot="reference" class="appraise_btn"
                                         :class="{default:detail.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)===-1}"
                                         :style="{backgroundColor:initReportType(scope.row.report_type).color,borderColor:initReportType(scope.row.report_type).color,color:initReportType(scope.row.report_type).color?'#fff':'#ccc'}">
                                        {{ initReportType(scope.row.report_type).text }}
                                    </div>
                                </el-popover>
                                <!--</div>-->
                                <!--<div v-else>-</div>-->
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="120px">
                            <template slot-scope="scope">
                                <editReport enter="btn" :report_info="scope.row"></editReport>
                                <el-button type="text" @click="delReport(scope.row.id,scope.$index)"
                                           style="margin-left: 10px;">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <p v-if="report_time&&no_report&&no_report.length>0">
                        本期未上报人员：{{ no_report.join('、') }}</p>
                </div>
                <!--子任务-->
                <div class="detail_item table" v-if="childList.length>0">
                    <div class="title">子任务</div>
                    <el-table :data="childList">
                        <el-table-column type="index" label="序号" align="center"></el-table-column>
                        <el-table-column prop="task_title" label="标题" align="center"></el-table-column>
                        <el-table-column label="任务类别" align="center">
                            <template slot-scope="scope">
                                <span>{{ initTaskType(scope.row.task_type) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="任务起止时间" align="center" width="210px">
                            <template slot-scope="scope">
                                <span v-if="scope.row.start_time&&scope.row.end_time">
                                    {{ scope.row.start_time.slice(0, 10).replace(/-/g, '.') }}
                                    -
                                    {{ scope.row.end_time.slice(0, 10).replace(/-/g, '.') }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="任务负责人" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fuze_uids">{{
                                        scope.row.fuze_uids.map(item => item.dingding.nickname).join('、')
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="任务承办人" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.deal_uids">{{
                                        scope.row.deal_uids.map(item => item.dingding.nickname).join('、')
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <div class="operation">
                                    <el-button type="text"
                                               @click="SETHISTORT({key:'push',value:{type:'detail',task_id:scope.row.task_id,father_task_id:scope.row.father_task_id,task_rank:scope.row.task_rank}})">
                                        详情
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="button">
            <el-button size="small" type="primary"
                       @click="SETHISTORT({key:'push',value:{type:'edit',task_id:detail.task_id,father_task_id:detail.father_task_id,task_rank:detail.task_rank}})"
                       class="button_98px"
                       v-if="task_power.power.look==1||detail.create_uid.user_id===$store.state.user.current_user.userid"
            >编辑任务
            </el-button>
            <reportList :enter="2" style="margin-left: 15px;" :task_info="detail" @refresh="reportLists"></reportList>
            <addAppraise :enter="2" :task_info="detail" style="margin-left: 15px;"></addAppraise>
            <addRegister :enter="2" :task_info="detail" style="margin-left: 15px;"></addRegister>
            <delTask :enter="2" :task_info="detail" style="margin-left: 15px;" @refresh="$emit('back')"
                     v-if="task_power.power.look==1||detail.create_uid.user_id===$store.state.user.current_user.userid"></delTask>
        </div>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import computedData from '@/mixins/computedData.js';
import {mapState, mapMutations} from 'vuex';
import myPublic from '@/utils/public.js';
import dingDisk from '@/utils/ding_disk';
import reportList from '@c/task/report/report_list.vue';
import addAppraise from '@c/task/appraise/add_appraise.vue';
import addRegister from '@c/task/register/add_register.vue';
import delTask from '@c/task/delete/delete_task.vue';
import editReport from '@c/task/report/edit_report.vue';

export default {
    mixins: [computedData],
    components: {reportList, addAppraise, addRegister, delTask, editReport},
    data() {
        return {
            detail: {}, childList: [], report_param: {}, reportList: [],
            reportTime: [], report_time: '',//任务的上报周期
            no_report: [],//本期未上报人员
            all_no_report: [],//所有期数未上报人员
        };
    },
    computed: {
        ...mapState('task', [
            'history'
        ]),
        task_id() {
            return this.$store.state.task.history[this.$store.state.task.history.length - 1].task_id;
        },
        history_length() {
            return this.$store.state.task.history.length;
        },
        task_power() {
            return this.$store.state.user.current_user.task_power;
        }
    },
    methods: {
        ...mapMutations('task', [
            'SETHISTORT'
        ]),
        taskDetail() {
            this.detail = {
                task_id: '',
                task_title: '',
                father_task_id: 0,
                task_rank: 0,
                task_year: '',
                time: '',
                task_des: '',
                file_ids: '',
                task_type: '',
                task_appraise: '',
                create_uid: {},
                fuze_uids: [],
                deal_uids: [],
                task_quality: ''
            };
            api.task.taskDetail({task_id: this.task_id}).then(res => {
                if (res.data && res.data.rows) {
                    if (res.data.rows.task_info) {
                        let task_info = res.data.rows.task_info;
                        this.detail.task_id = task_info.task_id;
                        this.detail.father_task_id = task_info.father_task_id;
                        this.detail.task_rank = task_info.task_rank;
                        this.detail.task_title = task_info.task_title;
                        this.detail.task_year = task_info.task_year;
                        this.detail.task_appraise = task_info.task_appraise;
                        this.detail.show_round = this.initShowRound(task_info.round);
                        if (task_info.start_time && task_info.end_time) {
                            this.detail.time = [
                                task_info.start_time.slice(0, 10).replace('-', '年').replace('-', '月') + '日',
                                task_info.end_time.slice(0, 10).replace('-', '年').replace('-', '月') + '日'
                            ];
                        }
                        let regN = /\n/g;
                        let regR = /\r/g;
                        if (task_info.task_des) {
                            this.detail.task_des = task_info.task_des.replace(regN, "<br />").replace(regR, "<br />");
                        }
                        if (myPublic.isJsonString(task_info.file_ids)) {
                            this.detail.file_ids = JSON.parse(task_info.file_ids);
                        }
                        this.detail.task_type = task_info.task_type;
                        this.detail.task_quality = task_info.task_quality;
                        if (res.data.rows.user_info) {
                            res.data.rows.user_info.forEach(user => {
                                if (user.task_id === this.detail.task_id) {
                                    if (user.user_type == 1) {
                                        this.detail.create_uid = user;
                                    }
                                    if (user.user_type == 2) {
                                        this.detail.fuze_uids.push(user);
                                    }
                                    if (user.user_type == 4) {
                                        this.detail.deal_uids.push(user);
                                    }
                                }
                            });
                        }
                        this.childTaskList();
                        this.getReportTime();
                        this.yuqiNoReport();
                    }
                }
            });
        },
        //子任务
        childTaskList() {
            // this.childList=[];
            api.task.childTaskList({father_task_id: this.task_id}).then(res => {
                if (res.data && res.data.row) {
                    this.childList = res.data.row.task_lists || [];
                    if (this.childList.length > 0 && res.data.row.user_info && res.data.row.user_info.length > 0) {
                        this.childList.forEach(item => {
                            item.create_uid = {};
                            item.fuze_uids = [];
                            item.deal_uids = [];
                            res.data.row.user_info.forEach(user => {
                                if (user.task_id === item.task_id) {
                                    if (user.user_type == 1) {
                                        item.create_uid = user;
                                    }
                                    if (user.user_type == 2) {
                                        item.fuze_uids.push(user);
                                    }
                                    if (user.user_type == 4) {
                                        item.deal_uids.push(user);
                                    }
                                }
                            });
                        });
                    }
                }
            });
        },
        //获取上报周期
        getReportTime() {
            api.task.getReportTime({task_id: this.task_id}).then(res => {
                let list = [];
                if (res.data && res.data.state == 1 && res.data.lists && Array.isArray(res.data.lists)) {
                    res.data.lists.forEach(item => {
                        if (item.report_time) {
                            list.push(item.report_time.slice(0, 10));
                        }
                    });
                }
                this.reportTime = list;
                this.reportLists();
            });
        },
        //上报列表
        reportLists() {
            let param = {
                task_id: this.task_id,
                pagesize: 9999,
            };
            if (!this.task_power.power.look) {
                param.user_id = this.$store.state.user.current_user.userid;
            }
            if (this.report_time) {
                param.report_time = this.report_time;
            }
            api.task.reportLists(param).then(res => {
                if (res.data) {
                    this.reportList = res.data.lists || [];
                    let deal_names = this.detail.deal_uids.map(item => item.dingding.nickname);//承办人名称集合
                    let report_names = [];//已上报人员名称
                    this.reportList.forEach(item => {
                        let regN = /\n/g;
                        let regR = /\r/g;
                        if (item.content) {
                            item.new_content = item.content.replace(regN, "<br />").replace(regR, "<br />");
                        } else {
                            item.new_content = '';
                        }
                        if (myPublic.isJsonString(item.file_ids)) {
                            item.file_ids = JSON.parse(item.file_ids);
                        } else {
                            item.file_ids = [];
                        }
                        item.show_report_time = '';
                        if (item.report_time) {
                            item.show_report_time = item.report_time.slice(0, 10);
                        }
                        if (item.create_time) {
                            item.create_time = item.create_time.slice(0, -3);
                        }
                        if (item.deal_user_info && item.deal_user_info.nickname) {
                            report_names.push(item.deal_user_info.nickname);
                        }
                    });
                    if (this.report_time) {
                        this.no_report = deal_names.filter(a => report_names.indexOf(a) === -1);
                    } else {
                        this.no_report = [];
                    }
                }
            });
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info);
        },
        //上报评价
        taskRrportAppraise(obj, type) {
            if (type === obj.report_type) return;
            api.task.taskRrportAppraise({id: obj.id, task_id: obj.task_id, type: type}).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '任务上报评价成功',
                        type: 'success'
                    });
                    this.$set(obj, 'report_type', type);
                }
            });
        },
        delReport(id, index) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要删除该条任务上报信息吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.task.reportDelete({id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '任务上报删除成功',
                                    type: 'success'
                                });
                                this.reportList.splice(index, 1);
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        yuqiNoReport() {
            api.task.yuqiNoReport({task_id: this.task_id}).then(res => {
                let list = [];
                if (res.data && res.data.state == 1 && res.data.rows) {
                    for (let a in res.data.rows) {
                        if (res.data.rows[a] && Array.isArray(res.data.rows[a])) {
                            list.push({
                                date: a.slice(0, 10),
                                names: res.data.rows[a]
                            });
                        }
                    }
                }
                this.all_no_report = list;
            });
        }
    },
    watch: {
        'history_length': {
            handler: function (val) {
                this.taskDetail();
                // console.log(this.history,'弹窗跳转流程')
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="less">
div.detail_box {
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;

    .detail {
        flex: 1;
        min-height: 0;

        .detail_item.items {
            .item:first-of-type {
                &.long {
                    /*box-shadow: none !important;*/

                    /*.left {*/
                    /*box-shadow: 0 1px #e4e7ec !important;*/
                    /*}*/
                }
            }

            .task_title {
                position: relative;

                .task_appraise {
                    position: absolute;
                    top: 20px;
                    left: 326px;
                    width: 140px;
                    height: 80px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }

        .all_no_report {
            padding: 0 33px;
            line-height: 1.6em;

            p {
                color: #e6a23c;
                font-weight: 700;
            }
        }

        .table {
            .files {
                .item {
                    text-align: left;
                    line-height: 16px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:hover {
                        color: #3396fb;
                    }
                }
            }

            //评价按钮
            .appraise_btn {
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 68px;
                line-height: 28px;
                text-align: center;
                transition: all 0.3s;
                font-size: 12px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                &.default {
                    cursor: default;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .report_table {
            .title {
                position: relative;

                .report_time_select {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: flex;
                    align-items: center;

                    span {
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                }
            }

            p {
                padding: 10px
            }
        }
    }

    .button {
        flex: 0 0 50px;
    }
}
</style>