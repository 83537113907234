<template>
	<div class="edit_file_name">
		<div class="icon" @click="open">
			<img class="default" src="../assets/img/edit.png" alt="" />
			<img class="hover" src="../assets/img/edit_hover.png" alt="" />
		</div>
		<el-dialog
			title="修改附件名称"
			:visible.sync="show"
			width="500px"
			class="edit_file_name_dialog"
			append-to-body
			@closed="close"
		>
			<el-input
				v-model="new_name"
				clearable
				placeholder="请输入新的附件名称"
			></el-input>
			<div class="button">
				<el-button class="button_88px" size="mini" type="primary" @click="editName"
					>保存</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		//父组件传入的附件数组
		file_list: {
			type: Array,
			require: true,
			default() {
				return [];
			},
		},
		index: {
			type: Number,
			require: true,
			default: 0,
		},
	},
	data() {
		return {
			show: false,
			list: [],
			new_name: "",
		};
	},
	methods: {
		//修改附件名称弹出框
		open() {
			this.list = JSON.parse(JSON.stringify(this.file_list));
			const file = this.file_list[this.index];
			let old_name = JSON.parse(JSON.stringify(file.fileName));
			this.new_name = old_name.split(".")[0];
			this.show = true;
		},
		editName() {
			if (this.new_name) {
				const key = "fileName";
				const suffix = this.list[this.index][key].split(".").pop();
				this.$set(this.list[this.index], key, `${this.new_name}.${suffix}`);
				this.$emit("editNameBack", this.list); //修改后的附件数组传回父组件
				console.log("this.list", this.list);
				this.show = false;
			}
		},
		close() {
			this.show = false;
			this.list = [];
			this.new_name = "";
		},
	},
};
</script>

<style lang="less">
.edit_file_name_dialog {
	.el-dialog__body {
		padding: 20px 35px 10px 35px !important;
	}
}
</style>
