<template>
    <el-dialog title="任务上报" :visible.sync="show" width="500px" append-to-body>
        <div class="content">
            <el-input type="textarea" :rows="4" placeholder="请填写任务上报内容" v-model="content"></el-input>
            <div class="files" v-if="file_ids&&file_ids.length>0">
                <fileListComp :file_list="file_ids" @change="editNameBack" />
            </div>
            <div class="add">
                <el-button size="mini" type="text" @click="updateFile">上传附件</el-button>
            </div>
        </div>
        <div class="button">
            <el-button class="button_88px" size="small" type="primary" @click="save">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import api from '@/api/methods/index';
    import dingDisk from '@/utils/ding_disk';
    import fileListComp from '@/components/file/index.vue'

    export default {
        components:{fileListComp},
        props:['report_id','report_time','task_info'],
        data(){
            return{
                show:false,content:'',file_ids:[],
            }
        },
        methods:{
            save(){
                if(!this.content){
                    this.$message({message:'请填写任务上报内容!', type:'warning'})
                    return;
                }
                let param={
                    task_id:this.task_info.task_id,
                    report_uid:this.$store.state.user.current_user.userid,
                    report_id:this.report_id,
                    report_time:this.report_time,
                    content:this.content,
                    file_ids:JSON.stringify(this.file_ids)
                }
                /*let fuze_id=0,deal_id=0;
                this.task_info.fuze_uids.forEach(user=>{
                    if(user.dingding.userid===param.report_uid){
                        fuze_id=1;
                    }
                })
                this.task_info.deal_uids.forEach(user=>{
                    if(user.dingding.userid===param.report_uid){
                        deal_id=1;
                    }
                })
                //既是负责人又是承办人则以负责人身份上报任务
                if(fuze_id&&deal_id){
                    param.user_type=2;
                }else if(fuze_id){
                    param.user_type=2;
                }else if(deal_id){
                    param.user_type=4;
                }*/
                param.user_type = 4;//角色定位承办人才能上报
                api.task.taskReport(param).then(res=>{
                    if(res.data.state==1){
                        this.$message({
                            message: '任务上报成功',
                            type: 'success'
                        });
                        this.$emit('refresh');//母组件中传递过来刷新数据的方法
                        this.show=false;
                    }
                })
            },
            //上传附件
            updateFile(){
                dingDisk.dingDiskUpdate(res=>{
                    this.file_ids=[...this.file_ids,...res.data];
                })
            },
            //预览附件
            previewFile(file_info){
                dingDisk.previewFile(file_info)
            },
            //修改名称后的附件数组
            editNameBack(file_list) {
                this.file_ids = file_list;
            },
        },
        watch:{
            'report_id':{
                handler:function(val){
                    if(val){
                        this.show=true;
                        this.content='';
                        this.file_ids=[];
                    }
                },
                immediate:true
            },
            'show':{
                handler:function(val){
                    if(!val){
                        this.$emit('clearId')
                    }
                },
                immediate:true
            }
        }
    }
</script>

<style scoped lang="less">
    /deep/ div.el-dialog{
        .content{
            padding:20px 20px 10px 20px;
            flex:1;
            min-height: 0;
            overflow-y:overlay;
            .title{
                padding:10px 0;
            }
            .el-textarea .el-textarea__inner{
                resize: none;
            }
            .files{
                padding-top: 10px;
                .item{
                    display:flex;
                    align-items: center;
                    margin-bottom: 5px;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    span{
                        flex:1;
                        min-width: 0;
                        font-size:12px;
                        cursor: pointer;
                        transition:color 0.3s;
                        &:hover{
                            color:#3396fb;
                        }
                    }
                    div.icon_del{
                        flex-shrink: 0;
                        width:12px;
                        height:12px;
                        margin-left: 10px;
                        background-size:100%;
                        background-repeat: no-repeat;
                        background-position: center center;
                        cursor: pointer;
                        background-image: url("../../../assets/img/del.png");
                        &:hover{
                            background-image: url("../../../assets/img/del_hover.png");
                        }
                    }
                    .icon {
                        width: 12px;
                        height: 12px;
                        position: relative;
                        cursor: pointer;

                        img {
                            width: 12px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;

                            &.default {
                                opacity: 1;
                            }

                            &.hover {
                                opacity: 0;
                            }
                        }

                        &:hover {
                            img {
                                &.default {
                                    opacity: 0;
                                }

                                &.hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        .button{
            border:none!important;
        }
    }
</style>