<template>
	<div>
		<div class="file" v-for="(item, index) in file_list">
			<div class="text" @click="dingDisk.previewFile(item)">
				{{ item.fileName }}
			</div>
			<editFileName
				:file_list="file_list"
				:index="index"
				@editNameBack="emit('change', $event)"
				v-if="show_edit"
			></editFileName>
			<div class="icon" @click="remove(index)" v-if="show_edit">
				<img class="default" src="~@/assets/img/del.png" alt="" />
				<img class="hover" src="~@/assets/img/del_hover.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script setup>
import editFileName from "@c/edit_file_name.vue";
import dingDisk from "@/utils/ding_disk";

const props = defineProps({
	file_list: {
		type: Array,
		default: () => [],
	},
	show_edit: {
		type: Boolean,
		default: () => true,
	},
});
const emit = defineEmits(["change"]);
const remove = (index) => {
	emit(
		"change",
		props.file_list.filter((item, i) => i !== index)
	);
};
</script>

<style lang="less" scoped></style>
