<template>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="parent_form task_form">
        <div class="row">
            <div class="item long">
                <el-form-item label="任务名称" prop="task_title">
                    <el-input v-model="ruleForm.task_title" placeholder="请输入任务名称"></el-input>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务类别" prop="task_type">
                    <el-select size="medium" v-model="ruleForm.task_type" popper-class="global_select_popper">
                        <el-option v-for="item in taskType" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务年度" prop="task_year">
                    <el-date-picker size="medium" v-model="ruleForm.task_year" :clearable="false" type="year"
                                    value-format="yyyy" placeholder="任务年度" style="width: 100%;"></el-date-picker>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务起止时间" prop="time">
                    <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="-"
                                    start-placeholder="选择日期" end-placeholder="选择日期"
                                    :clearable="false" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </div>
            <div class="item">
                <el-form-item label="任务属性" prop="task_quality">
                    <el-select size="medium" v-model="ruleForm.task_quality" popper-class="global_select_popper">
                        <el-option v-for="item in taskQuality" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="item long file_box">
                <el-form-item label="任务描述" prop="task_des">
                    <el-input type="textarea" :rows="4" v-model="ruleForm.task_des" placeholder="请输入任务描述"
                              :style="{marginBottom:ruleForm.file_ids&&ruleForm.file_ids.length>0?'10px':0}"></el-input>
                    <fileListComp :file_list="ruleForm.file_ids" @change="editNameBack" />
                    <div>
                        <el-button type="text" @click="updateFile">上传附件</el-button>
                    </div>
                </el-form-item>
            </div>
            <div class="item users">
                <el-form-item label="任务负责人" prop="fuze_uids">
                    <span class="names" v-if="ruleForm.fuze_uids&&ruleForm.fuze_uids.length>0">{{ruleForm.fuze_uids.map(item=>item.dingding.nickname).join('、')}}
                        <i class="el-icon-circle-close" @click="ruleForm.fuze_uids=[]"></i>
                    </span>
                    <el-button type="text" @click="choosePeople('fuze_uids')">选择任务负责人
                    </el-button>
                </el-form-item>
            </div>
            <div class="item users">
                <el-form-item label="任务承办人" prop="deal_uids">
                    <span class="names" v-if="ruleForm.deal_uids&&ruleForm.deal_uids.length>0">{{ruleForm.deal_uids.map(item=>item.dingding.nickname).join('、')}}
                        <i class="el-icon-circle-close" @click="ruleForm.deal_uids=[]"></i>
                    </span>
                    <el-button type="text" @click="choosePeople('deal_uids')">选择任务承办人
                    </el-button>
                </el-form-item>
            </div>
            <div class="item round" v-if="!task_rank">
                <el-form-item label="任务上报频率" prop="round">
                    <span v-if="ruleForm.round" style="margin-right: 10px;">{{initShowRound(ruleForm.round)}}</span>
                    <chooseRound :parent_task="ruleForm" @roundChange="roundChange"></chooseRound>
                </el-form-item>
            </div>
        </div>
        <div class="button">
            <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
        </div>
    </el-form>
</template>

<script>
    import api from '@/api/methods/index';
    import peoplePicker from '@/utils/people_picker';
    import {mapState, mapMutations} from 'vuex';
    import chooseRound from '@c/task/round/choose_round.vue';
    import computedData from '@/mixins/computedData.js';
    import dingDisk from '@/utils/ding_disk'
    import fileListComp from '@/components/file/index.vue'

    export default {
        components: {chooseRound,fileListComp},
        mixins: [computedData],
        data() {
            const check_fuze_uids = (rule, value, callback) => {
                if (value.length === 0) {
                    callback(new Error('请选择任务负责人'));
                } else {
                    callback();
                }
            };
            const check_deal_uids = (rule, value, callback) => {
                if (value.length === 0) {
                    callback(new Error('请选择任务承办人'));
                } else {
                    callback();
                }
            };
            const check_round = (rule, value, callback) => {
                if (!value && !this.task_rank) {
                    callback(new Error('请选择任务上报频率'));
                } else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    father_task_id: 0,
                    task_rank: 0,
                    task_title: '',
                    task_year: new Date().getFullYear().toString(),
                    time: '',
                    task_des: '',
                    file_ids: [],
                    task_type: '',
                    fuze_uids: [],
                    deal_uids: [],
                    round: '',
                    task_quality: ''
                },
                rules: {
                    task_title: [{required: true, message: '请输入任务名称', trigger: 'blur'}],
                    task_type: [{required: true, message: '请选择任务类别', trigger: 'change'}],
                    task_year: [{required: true, message: '请选择任务年度', trigger: 'change'}],
                    time: [{required: true, message: '请选择建设周期', trigger: 'change'}],
                    task_quality: [{required: true, message: '请选择任务属性', trigger: 'change'}],
                    // fuze_uids: [{required: true, validator: check_fuze_uids, trigger: 'change'}],
                    // deal_uids: [{required: true, validator: check_deal_uids, trigger: 'change'}],
                },
                taskType: [
                    {value: 1, label: '项目类'},
                    {value: 2, label: '党建类'},
                    {value: 3, label: '日常管理类'}
                ],
                taskQuality: [
                    {value: 1, label: '常规性'},
                    {value: 2, label: '临时性'},
                ]
            }
        },
        computed: {
            ...mapState('task', [
                'history'
            ]),
            task_id() {
                return this.$store.state.task.history[this.$store.state.task.history.length - 1].task_id
            },
            father_task_id() {
                return this.$store.state.task.history[this.$store.state.task.history.length - 1].father_task_id
            },
            task_rank() {
                return this.$store.state.task.history[this.$store.state.task.history.length - 1].task_rank
            },
            history_length() {
                return this.$store.state.task.history.length;
            },
        },
        methods: {
            ...mapMutations('task', [
                'SETHISTORT'
            ]),
            submitForm() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.task_title.length > 100) {
                            this.$message({message: '任务名称不能大于100字!', type: 'warning'});
                            return;
                        }
                        let param = {
                            father_task_id: this.father_task_id,
                            task_rank: this.task_rank,
                            task_title: this.ruleForm.task_title,
                            task_year: this.ruleForm.task_year,
                            task_des: this.ruleForm.task_des,
                            file_ids: JSON.stringify(this.ruleForm.file_ids),
                            task_type: this.ruleForm.task_type,
                            create_uid: this.$store.state.user.current_user.userid,
                            fuze_uids: this.ruleForm.fuze_uids.map(item => item.dingding.userid).join(','),
                            deal_uids: this.ruleForm.deal_uids.map(item => item.dingding.userid).join(','),
                            task_quality: this.ruleForm.task_quality
                        }
                        if (this.ruleForm.time && this.ruleForm.time.length > 0) {
                            param.start_time = this.ruleForm.time[0];
                            param.end_time = this.ruleForm.time[1];
                        }
                        if (!param.task_rank) {
                            if (!this.initShowRound(this.ruleForm.round)) {
                                this.$message({message: '请选择任务上报频率', type: 'warning'});
                                return;
                            }
                            if (this.ruleForm.round.round_type === 'once') {
                                let round_time = this.ruleForm.round.child.date_time + ' 00:00:00',
                                    start_time = param.start_time + ' 00:00:00',
                                    end_time = param.end_time + ' 00:00:00';
                                if (round_time < start_time || round_time > end_time) {
                                    this.$message({message: '自定义上报日期必须在任务起止时间以内!', type: 'warning'});
                                    return;
                                }
                            }
                            param.round = JSON.stringify(this.ruleForm.round);
                        }
                        api.task.addTask(param).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '新建任务成功',
                                    type: 'success'
                                });
                            }
                        })
                        this.$emit('back');
                    }
                })
            },
            //选择人员
            choosePeople(data) {
                peoplePicker.peoplePicker(this.ruleForm[data].map(item => item.dingding.userid), (res) => {
                    let users = [];
                    res.users.forEach(user => {
                        if (user.emplId) {
                            users.push(
                                {
                                    dingding: {
                                        avatar: user.avatar,
                                        nickname: user.name,
                                        userid: user.emplId
                                    }
                                }
                            )
                        }
                    });
                    this.ruleForm[data] = users;
                }, true)
            },
            roundChange(round) {
                this.ruleForm.round = round;
            },
            //上传附件
            updateFile() {
                dingDisk.dingDiskUpdate(res => {
                    this.ruleForm.file_ids = [...this.ruleForm.file_ids, ...res.data];
                })
            },
            //预览附件
            previewFile(file_info) {
                dingDisk.previewFile(file_info)
            },
            //修改名称后的附件数组
            editNameBack(file_list) {
                this.ruleForm.file_ids = file_list;
            },
        },
        watch: {
            'history_length': {
                handler: function (val) {
                    this.$nextTick(() => {
                        this.$refs.ruleForm.resetFields();
                    })
                    this.ruleForm = {
                        father_task_id: 0,
                        task_rank: 0,
                        task_title: '',
                        task_year: new Date().getFullYear().toString(),
                        time: '',
                        task_des: '',
                        file_ids: [],
                        task_type: '',
                        fuze_uids: [],
                        deal_uids: [],
                        round: '',
                        task_quality: ''
                    }
                    // console.log(this.history,'弹窗跳转流程')
                },
                immediate: true
            }
        }
    }
</script>

<style scoped lang="less">
    .task_form {
        .item.round {
            /deep/ .el-form-item__label:before {
                content: '*';
                color: #F56C6C;
                margin-right: 4px;
            }
        }

        .item.users {
            span.names {
                border-radius: 5px;
                position: relative;
                padding-right: 15px;

                &:hover {
                    i {
                        opacity: 1;
                    }
                }

                i {
                    position: absolute;
                    right: 1px;
                    top: -3px;
                    cursor: pointer;
                    opacity: 0;
                    transition: all 0.3s;

                    &:hover {
                        color: #3396fb;
                    }
                }
            }
        }
    }
</style>