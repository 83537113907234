<template>
    <div class="add_appraise" v-if="task_power.power.appraise">
        <!--v-if="task_info.fuze_uids.map(item => item.dingding.userid).indexOf($store.state.user.current_user.userid)!==-1">-->
        <div v-if="enter==1" @click="open">任务评价</div>
        <el-button size="small" type="primary" class="button_98px" v-if="enter==2" @click="open">任务评价</el-button>
        <el-dialog title="任务评价" :visible.sync="show" width="500px" append-to-body>
            <div class="content add_appraise_content">
                <div class="radio_group">
                    <div v-for="(item,index) in taskAppraise" :key="index+1"
                         :class="{radio:true,active:task_appraise===item.value}" @click="task_appraise=item.value">
                        <div class="icon">
                            <img class="default" src="../../../assets/img/radio.png" alt="">
                            <img class="active" src="../../../assets/img/radio_active.png" alt="">
                        </div>
                        <div class="label" :style="{color:item.color}">{{item.label}}</div>
                    </div>
                </div>
            </div>
            <div class="button add_appraise_button">
                <el-button class="button_88px" size="small" type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';

    export default {
        props: ['task_info', 'enter'],
        data() {
            return {
                show: false, task_appraise: '',
                taskAppraise: [
                    {value: 1, label: '优秀', color: '#ff2d2d'},
                    {value: 2, label: '良好', color: '#fe842b'},
                    {value: 3, label: '合格', color: '#3db00a'},
                    {value: 4, label: '不合格', color: '#666666'},
                ]
            }
        },
        computed:{
            task_power(){
                return this.$store.state.user.current_user.task_power
            }
        },
        methods: {
            open() {
                this.show = true;
                if (this.task_info.task_appraise) {
                    this.task_appraise = JSON.parse(JSON.stringify(this.task_info.task_appraise));
                } else {
                    this.task_appraise = '';
                }
            },
            save() {
                if (!this.task_appraise) {
                    this.$message({message: '请选择任务评价!', type: 'warning'})
                    return;
                }
                api.task.taskAppraise({
                    task_id: this.task_info.task_id,
                    task_appraise: this.task_appraise
                }).then(res => {
                    if (res.data.state == 1) {
                        this.$message({
                            message: '任务评价成功',
                            type: 'success'
                        });
                        this.$set(this.task_info, 'task_appraise', this.task_appraise)
                        this.show = false;
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    div.add_appraise_content {
        padding: 60px 30px 60px 0;

        .radio_group {
            justify-content: space-between;
        }
    }

    .button.add_appraise_button {
        border: none !important;
    }
</style>